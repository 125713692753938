import React from 'react';
import footerlogo from '../Images/footerlogo.png';
import facebookdiv from '../Images/facebook.png';
import twitterdiv from '../Images/twitter.png';
import instagramdiv from '../Images/instagram.png';
import pintrestdiv from '../Images/pintrest.png';


const FooterOne = () => {
  return (
    <>
    <div className='flex flex-col h-[100%] mx-auto bg-[#51DD11] text-white overflow-x-hidden ' id='contact'>
        <div className=' mx-auto  flex flex-wrap h-[70%] pl-[10%] py-4 md:px-8 lg:px-0 xl:px-8 md:py-8 lg:py-4 lg:w-[90%]'>
        <div className='flex flex-col'>
          <img src={footerlogo} alt='' className='w-1/4 h-20 mr-2' />
          <p id='footersessioninfop' className='text-sm w-[50%]'>
            Increase revenue and increase your membership management capabilities 
            with a high-performance solution that streamlines operations and maximizes engagement. 
            Managing diverse members and their needs could also be tasking and annoying… 
            So we have enabled even more capacity.
          </p>
        </div>
          <div className='flex flex-col h-[33%] lg:w-1/4 w-1/2 md:w-[33%] md:h-[52%] lg:h-[60%] justify-between xl:pl-4'>
             <h1 id='footersessionh' className='text-black text-[12px] leading-[20px] md:text-[14px] lg:text-[16px] font-bold lg:leading-[24px] h-[25%] md:h-[20%] font-inter'>Lagos Office</h1>
             <p id='footersessioninfop' className='text-[9px] leading-[15px] md:text-[12px] lg:text-[14px] md:leading-[21px] h-[70%] font-workSans font-medium'>Plot 1187, Abimbola Awoniyi Close,<br/>
                off Kasumu Ekemode Str, Via Saka <br />
                Tinubu Street, Victoria island, <br /> Nigeria.
             </p>
             <div className=' font-semibold text-[9px] leading-[15px] md:text-[12px] lg:text-[14px] md:leading-[21px] font-inter'>   
                <p id='footersessioninfop'>T: 234-1-9049320, 234-1-2710670-2</p>
                <p id='footersessioninfop' >E: info@vatebra.com</p>
                <p id='footersessioninfop' >E: customersupport@vatebra.com</p>
             </div>
          </div>
          <div className='flex flex-col h-[29%] lg:w-1/4 w-1/2 md:w-[36%] md:h-[46%] lg:h-[53%] justify-between lg:pl-8 md:pl-4'>
              <h1 id='footersessionh' className='text-black text-[12px] leading-[20px] md:text-[14px] lg:text-[16px] font-bold lg:leading-[24px] h-[25%] md:h-[20%] font-inter'>Abuja Office</h1>
              <p id='footersessioninfop' className='text-[9px] leading-[15px] md:text-[12px] lg:text-[14px] md:leading-[21px]  h-[70%] font-workSans font-medium '>Suite 302 Fortune Place, <br /> No 9 Nairobi Street, off <br /> Aminu Kano Cresent, <br /> Wuse 2, Abuja, Nigeria.</p>
              <div className='font-semibold text-[9px] leading-[15px] md:text-[12px] lg:text-[14px] md:leading-[21px] font-inter'>
                <p id='footersessioninfop' >T: 234-1-9049320, 234-1-2710670-2</p>
                <p id='footersessioninfop' >E: abujaoffice@vatebra.com</p>
              </div> 
          </div>
          <div className='flex flex-col h-[23%] p-0 lg:w-1/4 w-1/2 md:w-[30%] md:h-[39%] lg:h-[46%] justify-between md:pl-6 lg:pl-20'>
             <h1 id='footersessionh' className='text-black text-[12px] leading-[20px] md:text-[14px] lg:text-[16px] font-bold lg:leading-[24px] h-[25%] md:h-[20%] w-full font-inter'>Nairobi Office</h1>
             <p id='footersessioninfop' className='text-[10px] leading-[15px] md:text-[12px] lg:text-[14px] md:leading-[21px] h-[70%] font-medium font-workSans '>AG02, Madonna House, <br /> Westlands Road, Nairobi, <br /> Kenya</p>
             <p id='footersessioninfop' className='font-semibold text-[9px] leading-[15px] md:text-[12px] lg:text-[14px] md:leading-[21px] font-inter'>E: nairobioffice@vatebra.com</p>
          </div>
          <div className='flex flex-col h-[29%] lg:w-1/4 w-1/2 md:mt-4 lg:mt-0 md:w-[33%] md:h-[45%] lg:h-[53%] justify-between lg:pl-20'>
             <h1 id='footersessionh' className='text-black text-[12px] leading-[20px] md:text-[14px] lg:text-[16px] font-bold lg:leading-[24px] h-[25%] md:h-[20%] font-inter'>Accra Office</h1>
             <p id='footersessioninfop' className='text-[9px] leading-[15px] md:text-[12px] lg:text-[14px] md:leading-[21px] font-medium h-[70%] font-workSans'>202, Westland Boulevard, <br /> west Legon, Accra, Ghana, <br /> PO Box AN 5208 Accra- <br /> North, Accra Ghana.</p>
             <div className='font-semibold text-[9px] leading-[15px] md:text-[12px] lg:text-[14px] md:leading-[21px] font-inter'>
              <p id='footersessioninfop'>T: 233(0) 302402339</p>
              <p id='footersessioninfop'>E: ghanaoffice@vatebra.com</p>
             </div>
          </div>
          <div className='md:ml-[2%] lg:ml-[0] before:flex flex-col h-[18%] lg:w-[30%] w-4/5 md:w-[33%] lg:mt-4 lg:h-[27%] md:h-[33%] justify-between md:mt-4 xl:pl-4 text-white'>
              <h1 id='footersessionh' className='text-black text-[12px] leading-[20px] md:text-[14px] lg:text-[16px] font-bold lg:leading-[24px] h-[25%] md:h-[20%] font-inter'>Sierra Leone Office</h1>
              <div className='font-medium text-white text-[9px] mt-[2%] leading-[15px] md:text-[12px] lg:text-[14px] md:leading-[21px] font-inter'>
                <p id='footersessioninfop' >55A Wilkinson Road, Freetown, Sierra Leone</p>
                <p id='footersessioninfop'>T: +2349062547274</p>
                <p id='footersessioninfop'>E: info@vatebra.com</p>
              </div> 
          </div>

          <div id='footersessiontxt' className='flex flex-col h-[29%] lg:w-1/4 w-1/2 md:w-[36%] md:h-[46%] lg:h-[53%] justify-between lg:pl-8 md:pl-4'>
              <h1 id='footersessionh' className='text-black text-[12px] leading-[20px] md:text-[14px] lg:text-[16px] font-bold lg:leading-[24px] h-[25%] md:h-[20%] font-inter'>INFO</h1>
              <p id='footersessioninfop' className='text-[9px] leading-[15px] md:text-[12px] lg:text-[14px] md:leading-[21px]  h-[70%] font-workSans font-medium '>BLOG</p>
              <p id='footersessioninfop' className='text-[9px] leading-[15px] md:text-[12px] lg:text-[14px] md:leading-[21px]  h-[70%] font-workSans font-medium '>ABOUT US</p>
              <p id='footersessioninfop' className='text-[9px] leading-[15px] md:text-[12px] lg:text-[14px] md:leading-[21px]  h-[70%] font-workSans font-medium '>FAQ</p>
              <p id='footersessioninfop' className='text-[9px] leading-[15px] md:text-[12px] lg:text-[14px] md:leading-[21px]  h-[70%] font-workSans font-medium '>CONTACT US</p>

            </div>
            <hr className='my-6 border-white w-full' />
      <div className='flex items-center justify-between px-4 py-6 w-full'>
        <div className='flex items-center space-x-4'>
          
          <div className='bg-white h-10 w-10 rounded-full '> 
          <img src={facebookdiv} alt='Facebook Icon' className='w-6 h-6 ml-2 mt-2' />
          </div>
         
          <div className='bg-white h-10 w-10 rounded-full '> 
          <img src={twitterdiv} alt='Twitter Icon' className='w-6 h-6 ml-2 mt-2' />
          </div>

          <div className='bg-white h-10 w-10 rounded-full '> 
          <img src={instagramdiv} alt='Instagram Icon' className='w-6 h-6 ml-2 mt-2' />
          </div>

          <div className='bg-white h-10 w-10 rounded-full '> 
          <img src={pintrestdiv} alt='Pinterest Icon' className='w-6 h-6 ml-2 mt-2' />
          </div>

        </div>
        <div id='footersessioninfop' className='text-xs'>&copy; Copyright 2022 by Vatebra.com</div>
      </div>
      
        </div>
    </div>


    
    </>
  )
}

export default FooterOne;