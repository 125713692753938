import { Link } from "react-router-dom";




const Navbar = () => {
  return (
    <header>
      <img
        className="logo"
        src={require("../Images/logoroom.png")}
        alt="logo"
      />

      <input type="checkbox" className="check" id="toggle" />
      <label for="toggle" className="hamburger">
        <i className="fa-solid fa-bars"></i>
      </label>

      

      <div id="navigation" className="navigation">
        <Link to="/about">About</Link>
        <a href="/">Features</a>
        <a href="/">Blog</a>
        <a href="/">Contact</a>
        <button className="navbutton">Start Trial</button>
      </div>
    </header>
  );
};

export default Navbar;

