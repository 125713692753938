import React from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./Assets/css/Navbar.css";
import Navbar from "./Components/Navbar";
import Hero from "./Components/Hero";
import About from "./Components/About";
import DemoSession from "./Components/DemoSession";
import "./Assets/css/Features.css";
import Features from "./Components/Features";
import "./Assets/css/OtherFeatures.css";
import OtherFeatures from "./Components/OtherFeatures";
import Testimonials from "./Components/Testimonals";
import Article from "./Components/Article";
import FooterOne from "./Components/FooterOne";
const Home = () => {
  return (
    <div>
      <Navbar />
      <input type="checkbox" className="check" id="toggle" />
      <label for="toggle" className="hamburger">
        <FontAwesomeIcon icon={faBars} />
      </label>
      <Hero />
      <About />
      <DemoSession />
      <Features />
      <OtherFeatures />
      <Testimonials />
      <Article />
      <FooterOne />
    </div>
  );
};

export default Home;
