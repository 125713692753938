import React from 'react';
import desk from '../Images/desktop.png';

const DemoSession = () => {
  return (
    <section className="relative h-screen">
      
      <div
        className="absolute inset-0 h-2/3 md:h-72 w-full bg-cover bg-center"
        style={{ backgroundImage: `url(${desk})` }}
      ></div>
      
      <div className="absolute inset-0 bottom-0 md:bottom-20 flex items-center justify-center">
        <div className="w-full md:w-2/4 h-1/2 md:h-2/4 rounded-2xl border-white border-2">
          <iframe
            className="w-full h-full rounded-2xl"
            src="https://www.youtube.com/embed/ZQCQINjHICc"
            title="YouTube Video"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default DemoSession;
