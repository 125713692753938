import React, { useState } from 'react';
import { RxDotFilled } from 'react-icons/rx';
import articleImage1 from '../Images/Rectangle 44.png';
import articleImage2 from '../Images/Rectangle 46.png';
import articleImage3 from '../Images/Rectangle 47.png';

const articles = [
  {
    image: articleImage1,
    title: 'Lorem ipsum dolor sit amet',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel vehicula felis, non placerat risus. Etiam nec neque eu mi auctor facilisis.',
    contentMore:'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi doloribus hic quod!'
  },
  {
    image: articleImage2,
    title: 'Suspendisse in fringilla neque',
    content: 'Suspendisse in fringilla neque. Maecenas efficitur libero vel purus sagittis, a euismod nunc bibendum. Integer varius orci et dolor tempus, vitae fringilla turpis feugiat.',
    contentMore:'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi doloribus hic quod!'
  },
  {
    image: articleImage3,
    title: 'Praesent vitae libero tellus',
    content: 'Praesent vitae libero tellus. Aliquam bibendum purus vel risus ullamcorper feugiat. Sed in fringilla turpis. Fusce vehicula urna a malesuada vulputate.',
    contentMore:'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Modi doloribus hic quod!'
  },
];

const Article = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleReadMoreClick = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };



  return (
    <div className="bg-gray-100 py-16">
      <div className="container mx-auto text-center">
        <h2 id="articesessiontext" className="text-3xl text-[#383838] font-semibold mb-2">
          LATEST ARTICLE
        </h2>
        <p id="articesessiontext" className="text-[#383838] mb-8">
          Read Latest Updates
        </p>

        <div className="flex flex-col md:flex-row justify-center mb-8">
          <div className="md:w-10/12 md:flex md:space-x-6">
            {articles.map((article, index) => (
              <div
                key={index}
                className={`md:w-1/3 mb-6 md:mb-0 ${
                  expandedIndex === index ? 'opacity-100' : 'opacity-60'
                } transition-opacity duration-300`}
              >
                <div className="mb-4 rounded-lg overflow-hidden relative">
                  <img
                    src={article.image}
                    alt={article.title}
                    className="w-full h-auto"
                  />
                  <div className="p-4 bg-white">
                    <h3 id='articletext' className="text-l text-black font-semibold mb-2">{article.title}</h3>
                    <p id='articletext' className={`text-black text-[13px] mb-4 ${
                        expandedIndex === index ? '' : 'max-h-[6rem] overflow-hidden'
                    }`}
                    >
                      {article.content} {expandedIndex === index && article.contentMore}
                    </p>
                    <button 
                    id='articletext'
                      className="text-green-500 hover:text-green-600 font-semibold"
                      onClick={() => handleReadMoreClick(index)}
                    >
                      {expandedIndex === index ? 'Read Less' : 'Read More'}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        <div className="flex justify-center py-2">
          {articles.map((_, index) => (
            <div
              key={index}
              onClick={() => handleReadMoreClick(index)}
              className={`text-2xl cursor-pointer mx-2 ${
                index === expandedIndex ? 'text-black' : 'text-[#000000]'
              }`}
            >
              <RxDotFilled />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Article;
