import React, { useState } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import testimonial1 from '../Images/Ellipse 4.png';
import testimonial2 from '../Images/Ellipse 5.png';
import testimonial3 from '../Images/greenring.png';
import testimonial4 from '../Images/Ellipse 6.png';
import testimonial5 from '../Images/Ellipse 7.png';
import testimonial6 from '../Images/Groupp.png';
import testimonial7 from '../Images/people.png';
import testimonial8 from '../Images/greenring.png';
import testimonial9 from '../Images/squarehands.png';
import testimonial10 from '../Images/Ellipse 6.png';

const testimonials = [
  testimonial1, testimonial2, testimonial3, testimonial4, testimonial5,
  testimonial6, testimonial7, testimonial8, testimonial9, testimonial10,
];

const itemsPerPage = 1;

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const newIndex = (currentIndex - itemsPerPage + testimonials.length) % testimonials.length;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex = (currentIndex + itemsPerPage) % testimonials.length;
    setCurrentIndex(newIndex);
  };

  const displayedTestimonial = testimonials[currentIndex];

  return (
    <div className="bg-[#FAFAF9] py-8 md:py-16">
      <div className="container mx-auto text-center">
        <h2 id='testimonalhsection' className="text-2xl md:text-3xl text-[#535252] font-semibold mb-2">Testimonials</h2>
        <p id='testimonalpsection' className="text-[#535252] mb-4 md:mb-8">Some Impressions from our Customers</p>
        <p id='testimonalpsection' className="max-w-lg mb-6 mx-auto text-gray-700">
          “Memberoom is a trusted platform. I have been using it and it has been amazing with nice features.”
        </p>

        <div className="flex flex-col items-center mb-4 md:flex-row md:items-center md:mb-8">
          <button
            className="text-gray-700 hover:text-[#97F300] transition duration-300 mb-2 md:mb-0"
            onClick={prevSlide}
          >
            <BsArrowLeft size={24} />
          </button>
          <div className="w-40 h-40 rounded-full overflow-hidden mx-auto mb-4 md:mb-0">
            <img
              src={displayedTestimonial}
              alt={`Testimonial ${currentIndex + 1}`}
              className="w-full h-full object-cover"
            />
          </div>
          <button
            className="text-gray-700 hover:text-[#97F300] transition duration-300 mb-2 md:mb-0"
            onClick={nextSlide}
          >
            <BsArrowRight size={24} />
          </button>
        </div>

        <h3 id='testimonalhsection' className="text-xl md:text-2xl font-semibold mb-2">ICSAN</h3>
        <p id='testimonalhsection' className="text-[#97F300] mb-4">Head of Department</p>
      </div>
    </div>
  );
};

export default Testimonials;
