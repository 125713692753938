import React, { useState, useEffect } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';

import Groupy from '../Images/Groupy.png';
import Groupp from '../Images/Groupp.png';
import home from '../Images/homepage.png';

const slides = [
  {
    url: home,
    caption: 'Image 1 Caption',
    text: 'Memberoom helps you manage your organization effectively in four key areas: Registration, Management, Examination, Payment collection',
  },
  {
    url: Groupp,
    caption: 'Image 2 Caption',
    text: 'Memberoom helps you manage your organization effectively in four key areas: Registration, Management, Examination, Payment collection',
  },
  {
    url: Groupy,
    caption: 'Image 3 Caption',
    text: 'Memberoom helps you manage your organization effectively in four key areas: Registration, Management, Examination, Payment collection',
  },
];

const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const newIndex = (currentIndex - 1 + slides.length) % slides.length;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex = (currentIndex + 1) % slides.length;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

return (
<div className='max-w-[1400px] h-[740px] w-full m-auto relative group'>
<div
style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
className='w-full h-full bg-center bg-cover duration-500 relative flex items-center justify-center'
>
<div className='absolute bottom-0 p-4 text-white text-center'>
  <p id='textsection' className='text-[16px] md:text-2xl bg-opacity-80 w-4/5 mb-4 md:mb-96 mx-auto px-6 md:px-10 py-2 rounded'>
    {slides[currentIndex].text}
  </p>

  <button id='buttonsection' className='absolute bottom-4 md:bottom-[10%] left-1/2 transform -translate-x-1/2 bg-[#97F300] w-4/5 md:w-2/5 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none'>
  Register Now </button>
 
  
  

  <div className='flex top-4 justify-center py-2'>
    {slides.map((slide, slideIndex) => (
      <div
        key={slideIndex}
        onClick={() => goToSlide(slideIndex)}
        className='text-xl cursor-pointer'
      >
        <RxDotFilled />
      </div>
    ))}
  </div>
</div>
</div>

      <div className='hidden group-hover:block absolute top-1/2 -translate-x-0 -translate-y-1/2 left-5 text-xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>

      <div className='hidden group-hover:block absolute top-1/2 -translate-x-0 -translate-y-1/2 right-5 text-xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
    </div>
  );
};

export default Hero;
