import React from 'react';
import { FaLightbulb, FaRocket, FaCode, FaUser, FaChartLine, FaHandsHelping } from 'react-icons/fa';

const Features = () => {
  return (
    <div className="pt-1">
      <div className="container mx-auto -mt-20">
      <div id='cardresponsive' className="grid  gap-6">
        <div className="col-span-3 text-center mb-8">
          <h2 id='featurehsection' className="text-3xl text-[#535252] font-semibold mb-2 ">Features</h2>
          <p id='featurespsection' className="text-[#535252]">Explore the powerful features of Memberoom</p>
          </div>

         
          <div className="bg-white hover:bg-[#2BB60082] p-6 shadow-lg 
          hover:shadow-[#758EFE10] hover:text-white w-[100%] h-[400px] ml-5 "> 
            <div className="flex items-center mb-4">
              <FaLightbulb  className="text-black text-3xl mr-4" />
              <h3 id='featuresparagraph' className="text-lg font-semibold">AUDIT TRAIL</h3>
            </div>
            <p id='featurestext' className="text-black hover:text-white">
            A security feature that record step-by-step by which accounting 
            or trade data can be traced to its source chronologically. 
            The software can operate with the closed-looped controls, or as a 'closed system.
            </p>
          </div>

          
          <div className="bg-white hover:bg-[#2BB60082] p-6 shadow-lg 
          hover:shadow-[#758EFE10] hover:text-white  w-[100%] h-[400px] ml-5">
            <div className="flex items-center mb-4">
              <FaRocket className="text-black text-3xl mr-4" />
              <h3 id='featuresparagraph' className="text-lg  hover:text-white font-semibold">MEMBERSHIP GRADE</h3>
            </div>
            <p id='featurestext' className="text-black hover:text-white">
            Easily setup your association’s member and add members according to their grades and categories. 
            Visual, searchable and mobile-friendly member’s listings which include member’s details, pictures and more.
            </p>
          </div>

          
          <div className="bg-white hover:bg-[#2BB60082] p-6 shadow-lg 
          hover:shadow-[#758EFE10] hover:text-white w-[100%] h-[400px] ml-5 ">
            <div className="flex items-center mb-4">
              <FaCode className="text-black text-3xl mr-4" />
              <h3 id='featuresparagraph'className="text-lg  hover:text-white font-semibold">ONLINE MEMBERSHIP APPLICANT</h3>
            </div>
            <p id='featurestext' className="text-black  hover:text-white">
            Memberoom is made to streamline and ease how new members join and 
            proceed membership through a secure online applications and easy-to-use members only dashboard.
            </p>
          </div>

          
          <div className="bg-white hover:bg-[#2BB60082] p-6 ml-5 shadow-lg
          hover:shadow-[#758EFE10] hover:text-white transition duration-300 w-[100%] h-[400px]">
            <div className="flex items-center mb-4">
              <FaUser className="text-black text-3xl mr-4" />
              <h3 id='featuresparagraph' className="text-lg  hover:text-white font-semibold">MAXIMIZE MEMBER ENGAGEMENT</h3>
            </div>
            <p id='featurestext' className="text-black  hover:text-white">
            Connect with your community on a special level by giving them the power to
             engage with you on superior and organized scale. You'll never feel so 
             refreshed managing your membership organization from your desktop or mobile devices.

            </p>
          </div>

          
          <div className="bg-white hover:bg-[#2BB60082] p-6 shadow-lg 
          hover:shadow-[#758EFE10] hover:text-white w-[100%] h-[400px] ml-5">
            <div className="flex items-center mb-4">
              <FaChartLine className="text-black text-3xl mr-4" />
              <h3 id='featuresparagraph' className="text-lg hover:text-white font-semibold">USER-FRIENDLY NAVIGATION</h3>
            </div>
            <p id='featurestext' className="text-black hover:text-white">
            With our user-friendly interface members and administrators can 
            easily navigate around Memberoom. 
            You don’t have to be computer or tech native use and operate the platform.

            </p>
          </div>

          
          <div className="bg-white hover:bg-[#2BB60082] p-6 shadow-lg 
          hover:shadow-[#758EFE10]  hover:text-white w-[100%] h-[400px] ml-5">
            <div className="flex items-center mb-4">
              <FaHandsHelping className="text-black text-3xl mr-4" />
              <h3 id='featuresparagraph' className="text-lg hover:text-white  text-black font-semibold">SECURE MEMBERSHIP PAYMENT</h3>
            </div>
            <p id='featurestext' className="text-black hover:text-white">
            Memberoom receive online payments swiftly and securely through SSL encryption 
            certificate without saving credit card information. 
            Memberoom also supports a variety of payment gateways.

            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;

