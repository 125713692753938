import React from 'react';
import square from '../Images/squarehands.png';
import woman from '../Images/woman.png';

const About = () => {
  return (
    <section className="flex flex-col md:flex-row items-center justify-center py-16">
      <div className="w-full md:w-1/2 px-6 order-2 md:order-1">
        <h2 className="text-4xl font-semibold mb-2">
          <span id='aboutussection' className="text-[#535252]">Who</span> <span id='aboutussection' className="text-[#97F300] text-5xl">We Are</span>
        </h2>
        <h3 id='aboutussection' className="text-3xl text-[#535252] font-semibold mb-4">About Us</h3>
        <p id='aboutparagraph' className="text-lg text-gray-700 mb-4">
        Whether it's an organization, institution, association or a professional body you want to manage…
        you will agree that it takes optimal cohesion and accountability to achieve this.
        Memberoom provides an improved way to manage, engage and grow your membership base.
        Be it an association, a club or a non-profit, you can bring your community together with a highly scalable, 
        modest and intuitive membership management software that automates your tasks and scales with you as you grow.
        Memberoom help increase your productivity and saves time by combining great tools, 
        functionalities and features in an easy to use platform.

        </p>
        <button id='buttonsection' className="bg-[#97F300] w-60 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none">
          Request Demo Video
        </button>
      </div>
      <div className="w-full md:w-1/2 flex justify-center items-center px-6 mt-10 md:mt-0 order-1 md:order-2">
        <div className="relative rounded-10">
          <img src={square} alt="hands" className="mb-10 md:mb-0 ml-10 rounded-lg w-full" />
          <img src={woman} alt="she" className="absolute top-10 right-20 rounded-lg w-full" />
        </div>
      </div>
    </section>
  );
};

export default About;
