import React from 'react';
import { FaCheckDouble } from 'react-icons/fa';
import people from '../Images/people.png';

const OtherFeatures = () => {
  return (
    <div className="bg-[#97F300] mt-20">
      <div className="container mx-auto">
        <div id='otherfeaturesresponsive' className="mt-[20%] text-xl">
          <p id='otherfeaturestext' className="text-white w-[50%] mb-[1%] ">
            Make informed, data-driven decisions with our Memberoom solution.
            Step into the future of tech-driven membership management that saves time and increases productivity.
          </p>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-between">
          
          <div className="md:w-1/3 mb-6 md:mb-0">
            <img src={people} alt="Features" className="w-50" />
          </div>

          
        <div className="md:w-2/3 md:flex md:items-start md:pl-8">          {/* First List of Features */}
          <div className="mt-10 mr-8">
             <h4 id='otherfeaturestext' className="text-white text-1xl font-semibold mb-2">Other Features Include</h4>
             <p id='otherfeaturestext' className="text-white flex">
               <FaCheckDouble className="text-green-500 mr-2" />
                Member Address Label
             </p>
            <p id='otherfeaturestext' className="text-white flex">
                <FaCheckDouble className="text-green-500 mr-2" />
               Annual dues upload
             </p>
             <p id='otherfeaturestext' className="text-white flex">
                <FaCheckDouble className="text-green-500 mr-2" />
                 Result publishing
               </p>
               <p id='otherfeaturestext' className="text-white flex">
                 <FaCheckDouble className="text-green-500 mr-2" />
                Bulk messaging
               </p>
               <p id='otherfeaturestext' className="text-white flex">
                 <FaCheckDouble className="text-green-500 mr-2" />
                 Material Upload
               </p>
               <p id='otherfeaturestext' className="text-white flex">
                 <FaCheckDouble className="text-green-500 mr-2" />
                 Upgrade management
               </p>
               <p id='otherfeaturestext' className="text-white flex">
                 <FaCheckDouble className="text-green-500 mr-2" />
                Exam Categorization
               </p>
             </div>

            
             <div className="mt-[5%] ml-[10%]">
               <p id='otherfeaturestext' className="text-white flex">
                 <FaCheckDouble className="text-green-500 mr-2" />
                 Processing Practitioner Applicant
               </p>
               <p id='otherfeaturestext' className="text-white flex">
                <FaCheckDouble className="text-green-500 mr-2" />
                Payment alerts and notification
               </p>
              <p id='otherfeaturestext' className="text-white flex">
                <FaCheckDouble className="text-green-500 mr-2" />
                 Processing Student Applicant
               </p>
             <p id='otherfeaturestext' className="text-white flex">
                 <FaCheckDouble className="text-green-500 mr-2" />
                Financial performance reports
               </p>
               <p id='otherfeaturestext' className="text-white flex">
                 <FaCheckDouble className="text-green-500 mr-2" />
                 Member Upgrade Application
             </p>
               <p id='otherfeaturestext' className="text-white flex">
                 <FaCheckDouble className="text-green-500 mr-2" />
                 Knowledge Categorization
               </p>
               <p id='otherfeaturestext' className="text-white flex">
                 <FaCheckDouble className="text-green-500 mr-2" />
                 Examination Registration
               </p>
               <p id='otherfeaturestext' className="text-white flex">
                 <FaCheckDouble className="text-green-500 mr-2" />
                 Flexible approval flows
               </p>
            </div>
           </div>
        </div>
      </div>
    </div>
  );
};

export default OtherFeatures;










